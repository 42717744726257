<script lang="ts" setup>
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import type { Ref } from 'vue';
import type { OfferMedium } from '~/types/NewTypes/CatalogNew';

const props = defineProps<{ offers: OfferMedium[]; title?: string | null }>();

const existingOffers = computed(() => {
  return props.offers.filter((offer) => offer.id);
});

const isShowNavigation = computed(() => existingOffers.value.length > 4);

onMounted(() => {
  sliderInitialization();
});

const swiperInstance: Ref<any> = ref();
const swiperBlock = ref();
const swiperPaginationBlock = ref();

function sliderInitialization() {
  swiperInstance.value = new Swiper(swiperBlock.value, {
    breakpoints: {
      640: {
        slidesOffsetBefore: 12,
        spaceBetween: 24,
      },
      1024: {
        spaceBetween: 24,
        slidesOffsetBefore: 0,
      },
    },
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: '.swiper-button--next',
      prevEl: '.swiper-button--prev',
    },
    pagination: {
      clickable: true,
      el: swiperPaginationBlock.value,
      type: 'bullets',
    },
    slidesOffsetBefore: 12,
    slidesPerView: 'auto',
    spaceBetween: 12,
  });
}

const { sendProductEventToDataLayer } = useGtmModule();
</script>

<template>
  <section class="product-cards-gallery">
    <h2
      v-if="title"
      class="px-3 lg:px-0"
    >
      {{ title }}
    </h2>

    <div
      ref="swiperBlock"
      class="swiper product-cards-gallery__container"
    >
      <div class="swiper-wrapper">
        <ProductCard
          v-for="(offer, key) in existingOffers"
          :key="offer.id"
          :offer="offer"
          class="product-cards-gallery__item swiper-slide"
          @click="sendProductEventToDataLayer('select_item', offer, key)"
        />
      </div>

      <div
        v-if="isShowNavigation"
        class="swiper-navigation"
      >
        <div class="swiper-button swiper-button--prev">
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </div>

        <div class="swiper-button swiper-button--next">
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </div>
      </div>

      <div
        ref="swiperPaginationBlock"
        class="swiper-pagination"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.product-cards-gallery {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @include screen-lg {
    @include inner-content;
  }

  &__container {
    width: 100%;
  }

  &__item {
    width: 100%;
    max-width: 46vw;

    @include screen-sm {
      width: 270px;
    }
  }
}
</style>
